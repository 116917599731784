import React, { useState, useEffect } from "react"
import CoinSimu from "../../components/embedTools/coinSimu"
import { Box } from "../../components/commonElements"
import Header from "../../components/embedTools/header"
import CirclePlaceholder from "../../components/embedTools/circlePlaceholder"
import SeoEmb from "../../components/seo/seoEmb"
import DisableAd from "../../components/embedTools/disableAd"

const EmbedCoinPage = () => {
  const [smallSize, setSmallSize] = useState(true)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return
    const handleResize = () => {
      if (window.innerWidth < 300) {
        setSmallSize(true)
      } else {
        setSmallSize(false)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box backgroundColor="white" alignSelf="center" width="100%" zIndex="0">
      <DisableAd />
      <Header tool="coin" setShow={value => setShow(value)} />
      {show && <>{smallSize ? <CirclePlaceholder /> : <CoinSimu />}</>}
    </Box>
  )
}

export default EmbedCoinPage

export const Head = () => (
  <SeoEmb
    title="Embed Coin Flip Simulator | FlipSimu"
    canonical="https://flipsimu.com/"
  />
)
